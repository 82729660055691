import '../../splide/splide.min.css';
import Splide from "../../splide/splide.min.js";

let splide;
const splideSliders = {
  init: function () {
    splideSliders.slider();
    //actuHome.fuseWithAgenda();
  },
  slider: () => {
    // Les options par défaut pour ceux qui ne sont pas remplie dans l'attribut
    const options = {
      perPage: 3,
      perMove: 1,
      type: "slide",
      autoWidth: true,
      pagination: false,
      gap: "32px",
      autoplay: "pause",
      interval: 3000,
      pauseOnHover: true,
      i18n: {
        prev: "Elément précédent",
        next: "Elément suivant",
        first: "Premier élément",
        last: "Dernier élément",
        slideX: "Aller à l'élément %s",
        pageX: "Aller à la page %s",
        play: "Démarrer la lecture automatique",
        pause: "Pauser la lecture automatique",
        select: "Sélectionner un élément à afficher",
        slide: "Elément",
        slideLabel: "%s sur %s",
      },
      breakpoints: {
        1024: {
          perPage: 2,
          perMove: 1,
        },
        792: {
          perPage: 1,
          perMove: 1,
          gap: "16px"
        },
      },
    };

    let splideSelector = document.querySelectorAll( '.splide' );

    // Init all the elements with "splide" ClassName
    for ( let i = 0; i < splideSelector.length; i++ ) {
      splide = new Splide(splideSelector[ i ], options).mount();
    }
  },
}

document.addEventListener("DOMContentLoaded", () => {
  splideSliders.init();
});
